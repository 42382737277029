import React from 'react';
import Button from '@atoms/Button';
import { TypoXXS } from '@atoms/Typos';
import NeighborPlans from '@molecules/NeighborPlans';
import routes from '@utils/routes';
import styles from './css';

const AboutHafhPlan = (): JSX.Element => (
  <div className="about-hafh-plan">
    <NeighborPlans showAboutCoin={false} showLabel={false} showNotice={false} />
    <div className="plan-notice">
      <TypoXXS text="※ 初月はスタンダードプランのみご利用いただけます。2ヶ月目以降、ベーシックプランもご利用いただけます。" />
    </div>
    <Button
      label="登録する"
      link={routes.signup}
      size="x-large"
      width="responsive"
    />
    <style jsx={true}>{styles}</style>
  </div>
);

export default AboutHafhPlan;
