import css from 'styled-jsx/css';

const styles = css`
  .about-hafh-plan {
    text-align: center;
    .plan-notice {
      margin: 16px 0 32px;
    }
    :global(.btn) {
      display: inline-flex;
    }
  }
`;

export default styles;
