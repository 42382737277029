import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  .about-hafh {
    background-color: ${COLORS.black100};
    .section-inner {
      width: 100%;
      padding: 56px 16px;
      margin: auto;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        max-width: 960px;
      }
      .title {
        text-align: center;
        margin: 0 auto 32px;
        width: max-content;
        .divider {
          background-color: ${COLORS.primary};
          height: 3px;
          margin: 16px auto 0;
        }
      }
    }
  }
`;

export default styles;
